@tailwind base;
@tailwind components;
@tailwind utilities;

.test_div {
  background-image: url('/public/img/Group-84.png');
  background-position: right;
  /* background-size: 65%; */
  background-size: contain;
  display: flex;
  align-items: center;
}

.bg-bottom {
  background-image: url('/public/img/Group-85.png');
  background-position: left;
  background-size: contain;
  /* background-size: 80%; */
}

.selectIcon {
  display: flex;
  align-items: center;
}

.selectIcon > div {
  min-width: 100%;
  border: none;
}

.selectIcon > div > ::before,
::after {
  border: none;
}

.selectIcon::before {
  content: url('/public/Icon.png');
  padding-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-size: cover;
}

.sidebar-alert > div {
  margin-right: 0px;
}
