.trans-list .org-bar {
  padding-bottom: 6px;
}

.trans-list table {
  table-layout: fixed;
}

/*
@media (max-width: 930px) {
  .trans-list {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
  }

  / move content view to the right of list view []->[] /
  .trans-content {
    position: absolute;
    left: 100%;
  }
}
*/

.trans-list table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.trans-list table th {
  font-weight: normal;
}

.trans-item {
  cursor: pointer;
}

.trans-item--selected {
  background: rgba(0, 0, 0, 0.1);
  cursor: default;
  border-left: 0.5em solid #25da3c;
}

.trans-item:active,
.trans-item:hover {
  background: rgba(0, 0, 0, 0.2);
  transition: 0.2s;
}
.trans-list table th:first-of-type,
.trans-list table td:first-of-type {
  padding: 10px;
}

.trans-list td.name,
.trans-list th.name {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trans-list td.icon,
.trans-list th.icon {
  width: 40px;
}

.trans-list td.date,
.trans-list th.date {
  width: 160px;
}

/* TRANSCRIPT PLACEHOLDER */

.trans-placeholder {
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  width: 100%;
  color: #77838f;
  width: 460px;
}

.trans-list-container {
  margin-top: 25px;
}

.iconSvg {
  color: grey;
}

.share-content-area {
  min-height: 400px;
}
.dropdown-menu.show {
  z-index: 99;
  background: #fff;
  max-height: 350px !important;
}

/* Css for TypeAhead*/
.typeahead-input-group {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.rbt.typeahead {
  width: 86%;
}
.rbt-menu.dropdown-menu.show {
  width: 111.5% !important;
  box-shadow:
    0 3px 5px -1px rgb(0 0 0 / 20%),
    0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);
  padding: 10px 16px;
}
.role-group {
  position: absolute;
  right: 0;
  bottom: 0;
}
.dropdown-menu.show ul,
.dropdown-menu.show ul li {
  padding: 0;
}
li.email-list {
  padding-left: 0;
  padding-right: 0;
  align-items: center;
}
.rbt-input.focus .rbt-input-wrapper {
  border-bottom: 2px solid #22da3c;
}
.rbt-input-wrapper {
  padding: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
}
.rbt-menu-custom-option {
  display: none;
}
.rbt-input-wrapper .rbt-token {
  padding: 5px 25px 5px 5px;
  border: 1px solid #dadce0;
  margin-right: 10px;
  border-radius: 20px;
  color: #5f6368;
  font-weight: bold;
  position: relative;
}
.rbt-token .rbt-token-remove-button {
  bottom: 0;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 3px 7px;
  position: absolute;
  right: 0;
  text-shadow: none;
  top: -2px;
  background: transparent;
  border: none;
}
.rbt-token .rbt-token-remove-button .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.list-item-text span {
  color: #000;
  font-weight: 500;
}
.owner-type {
  font-style: italic;
}
li.MuiTreeItem-root.disabled > .MuiTreeItem-content {
  opacity: 0.5;
}

li.MuiTreeItem-root.disabled > .MuiTreeItem-content .MuiTreeItem-label:hover,
li.MuiTreeItem-root.disabled:focus > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent;
}
.folder-share-btn {
  padding: 2px;
  min-width: 12px;
  opacity: 0.8;
  margin-left: 12px;
}
svg.light {
  opacity: 0.6;
  width: 16px;
}
.send-to-right {
  position: absolute;
  left: 20px;
}
