.transcripts-container {
  margin-top: 55px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 20px;
  width: 100%;
  max-width: 1000px;
}

.transcripts-upload-area {
  min-width: 200px;
}

.transcripts-upload-area h2 {
  margin-bottom: 10px;
}

.transcripts-action-items-container {
  display: flex;
  justify-content: flex-end;
  /* background-color: blue */
}
.transcripts-action-items-container .searchbar {
  margin-bottom: 0.5px;
  margin-left: 5.5px;
}
.transcripts-action-items-container .searchbar > div {
  height: 36px;
}
.transcripts-action-items-container .transcript-action-btn {
  height: 40px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transcripts-action-items-btns {
  display: flex;
  position: relative;
  top: 9px;
}

/* .transcripts-action-items-container div.MuiToolbar-root .MuiTypography-h6,
.transcripts-action-items-container div.MuiToolbar-root div:nth-of-type(2) {
  display: none;
} */

.transcripts-action-items-container input {
  box-shadow: none !important;
}

.transcripts-action-items-highlighted {
  /* background-color: rgb(240, 240, 240); */
  position: relative;
}

.transcripts-action-items-highlighted div[class^='MuiToolbar-root'] {
  position: unset;
  background-color: rgb(240, 240, 240);
}

.transcripts-action-items-highlighted .MuiTypography-h6 {
  display: inline-block !important;
  position: absolute;
  left: 9px;
  top: 17px;
  color: black;
}

.transcripts-action-items-container .MuiToolbar-gutters {
  padding-left: 10px;
}

.transcripts-action-items-container .transcript-action-btn,
/* .transcripts-action-items-container .transcripts-export-btn {
  margin-left: 5px;
} */

.transcripts-action-items-container .transcript-action-btn {
  background-color: #fff;
}

.transcripts-action-items-container .transcripts-export-btn {
  position: relative;
  top: 5px;
}

/* .transcripts-action-items-container .transcripts-export-btn button[class^="MuiButtonBase-root"] {
  background-color: #fff;
  color: black;
}
.transcripts-action-items-container .transcripts-export-btn-disabled button[class^='MuiButtonBase-root'] {
  background-color: rgb(220, 220, 220);
  color: #fff
} */
.folder-share-btn {
  min-width: 14px;
  padding: 2px;
  margin-left: 10px;
  transform: scale(0.7);
}
.MuiLinearProgress-barColorPrimary {
  background-color: #25da3c !important;
}

.iconSize20 {
  font-size: 20px !important;
}
.iconSize12 {
  font-size: 12px !important;
}
