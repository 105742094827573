.MuiDialogActions .onBoarding-dialog-actions {
  display: flex;
  justify-content: space-between;
}

.tour-start-btn {
  background-color: 'black';
}

.header-text-tour {
  display: flex;
  justify-content: center;
  font-size: x-large;
  font-weight: normal;
}

.tour-box {
  max-width: 600px;
  width: auto;
}

.tour-step {
  display: flex;
  margin-left: 80px;
  font-size: medium;
  font-weight: normal;
  margin-top: 10px;
}

[data-tour-elem='controls'] {
  color: rgb(37, 218, 60);
  display: flex;
  justify-content: flex-end;
}

.next-btn-tour {
  background: rgb(37, 218, 60);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Roboto;
  color: #fff;
  border-radius: 3px;
  font-size: large;
  border: none;
}

.next-btn-tour:hover {
  cursor: pointer;
}

.back-btn-tour {
  border: none;
  background: none;
  color: #000;
  font-size: medium;
}

.back-btn-tour:hover {
  cursor: pointer;
}

.back-btn-tour:disabled {
  cursor: not-allowed;
}
