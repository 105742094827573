.filterSelector ul {
  position: absolute;
  width: 150px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}
.filterSelector button span {
  display: none;
}
.filterSelector button div {
  display: none;
}
.filterSelector div:nth-child(2) button {
  padding: 0px !important;
}
.instaIcon span {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
