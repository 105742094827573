.drop-parent-container {
  border: 2px solid #d9d9d9;
}

.drop-container {
  border-color: #77838f;
  border-radius: 5px;
  border-style: dashed;
  border-width: 2px;
  overflow: hidden;
}

.drop-container:hover {
  border-color: #37474f;
}

.drop-container-no-border {
  border: none;
  overflow: hidden;
  align-items: flex-start;
}

.drop-reject {
  border-color: red;
  background-color: #daa;
}

.uploader-div-btn svg {
  position: relative;
  top: 14px;
}

.uploader-submit-btn-container {
  margin-left: 142px;
}

.uploader-submit-btn {
  position: relative;
  top: -55px;
  background-color: #25da3c;
  color: white;
  font-weight: 100;
  margin-left: -20px;
}
.uploader-addFiles-btn {
  border: 1px solid #2ed33f;
  background-color: white;
  color: #2ed33f;
  font-weight: 100;
}

.uploader-preview-drop {
  width: 143px;
  margin-right: 20px;
}

.uploader-progress-container {
  padding: 10px 3%;
  border-bottom: none;
}

.uploader-preview-progress {
  height: 7px;
  background: #fff;
}

.uploader-preview-progress::-moz-progress-bar {
  background: #25da3b;
}

.uploader-preview-progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.uploader-preview-progress[value]::-webkit-progress-value {
  background: #25da3b;

  border-radius: 2px;
  background-size:
    35px 20px,
    100% 100%,
    100% 100%;
}

/* .ConfirmationDialogue{
   z-index: 1;
} */
#alert-dialog-slide-description {
  word-wrap: break-word;
}
