.transcript-details {
  width: 100%;
  background-color: rgb(250, 250, 250);
  min-height: 90vh;
}

.transcript-details-description textarea {
  width: 90%;
  resize: none;
  box-shadow: none !important;
  /* background-color: rgb(250, 250, 250); */
  color: black;
  overflow-y: hidden;
  border: none;
  margin-left: 4%;
  margin-top: 2%;
  margin-bottom: 20px;
}
.permission {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.go-back {
  background-color: #25da3c;
  color: #fff;
  border: 1px solid #25da3c;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
}

.permissionText {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.transcript-details-grid {
  margin-top: 10px;
  margin-bottom: 150px;
  padding: 50px 30px 50px 20px;
  width: 100%;
  min-height: calc(100vh -345px);
  background-color: #fff;
  border: 1px solid #e9ecef;
  position: relative;
}

.transcript-details-grid .action-panel-page-nav-bottom {
  position: absolute;
  bottom: 10px;
  justify-content: center;
  display: flex;
  width: calc(100% - 50px);
  font-size: 12px;
  color: #808080;
  align-items: center;
}
.action-panel-page-nav-bottom .paginationIcon {
  font-size: 11px !important;
}

.timecode-field {
  display: flex;
}

.timecode-field input {
  border: none !important;
  padding: 0 !important;
  padding-bottom: 5px !important;
}

/* .transcript-details-grid div["data-slate-editor"= true] {

} */

.transcript-layout-inner {
  display: grid;
  grid-template-columns: 12% auto;
  /* justify-content: center; */
  align-content: start;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.action-panel-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 10px;
  padding-left: 5%;
  position: sticky;
  top: 0;
  background: rgb(250, 250, 250);
  box-shadow: 0 0 0 3px rgb(250, 250, 250);
  z-index: 2;
}

.action-panel-grid-left {
  display: flex;
}

.action-panel-grid-right {
  display: flex;
  justify-content: flex-end;
  padding-right: 5%;
}

.action-panel-grid-right .action-panel-page-nav {
  align-self: center;
  padding: 0px 20px;
  color: #25da3c;
}

.action-panel-grid-right .action-panel-page-nav input {
  border: none;
  padding: 10px 0px 10px 10px;
  outline: none;
  box-shadow: none;
}

.action-panel-grid-right .action-panel-page-nav span.page-nav-seperator {
  margin-left: 5px;
  margin-right: 20px;
  color: #222222;
}

.transcript-details-container {
  width: 100%;
  max-width: 950px;
  /* padding: 0px 20px; */
  margin-left: auto;
  margin-right: auto;
}

.action-panel-grid-left-btn {
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  border: 1px solid rgb(119, 131, 143);
  /* outline: none; */
}
.selectorColor ul {
  position: absolute;
  width: 150px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}
.selectorColor button span {
  display: none;
}
.transcript-details-action-delete-btn {
  border: none;
  padding: 0px !important;
  margin-left: 12px !important;
}
.action-right-input {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 22px !important;
}
.pageIndexInput {
  width: auto !important;
}

.transcript-details-highlight-btn {
  margin-right: 5px;
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  border: 1px solid rgb(119, 131, 143);
  display: flex;
  justify-content: center;
  align-items: center;
  /* outline: none; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.speaker-input > div {
  border-radius: 8px;
}

.seo-chip > button {
  color: #9c47ed;
}

.transcript-details-export-btn button[class^='MuiButtonBase-root'] {
  background-color: #25da3c;
  color: #fff;
  border: 1px solid #25da3c;
  height: 40px;
}
.transcript-details-export-btn button[class^='MuiButtonBase-root']:hover {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.speaker-btn-grid {
  background-color: transparent;
  border: none;
  outline: none;
  align-self: center;
}
.speaker-btn-grid > div > div > label {
  font-size: 14px;
}

/* .transcript-details-tick-icon {
  align-self: center;
} */

.speaker-enable-icon {
  color: green;
  background-color: #fff;
  padding: 0px;
  margin-left: 5px;
  border: none;
  padding: 0px;
  margin-top: 2px;
  margin-bottom: 2px;
  opacity: 0;
}

.speaker-close-icon {
  color: red;
  background-color: #fff;
  margin-left: 5px;
  border: none;
  padding: 0px;
  margin-top: 2px;
  margin-bottom: 2px;
  opacity: 0;
}

.speaker-disabled-paragraph {
  display: flex;
  justify-content: space-between;
  align-self: center;
}

.speaker-btn-flex-container {
  display: flex;
  justify-content: space-between;
  color: black;
  font-family: Roboto;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  width: 100%;
  border: none;
  line-height: 22px;
  letter-spacing: 0.4px;
}
.speaker-btn-flex-container span:nth-child(2) {
  position: relative;
  top: 2px;
}
.speaker-btn-flex-container:hover {
  background-color: rgb(240, 240, 240);
  border-radius: 3px;
}
.speaker-btn-flex-container:hover .speaker-close-icon {
  background-color: rgb(240, 240, 240);
  opacity: 1;
  font-size: 15px;
  font-style: normal;
}

.speaker-btn-flex-container:hover .speaker-enable-icon {
  background-color: rgb(240, 240, 240);
  opacity: 1;
  font-size: 15px;
  font-style: normal;
}

.speaker-name-btn {
  color: #808080;
}

.action-panel-grid-left-btn:hover {
  background-color: rgb(250, 250, 250);
  cursor: pointer;
}

.action-panel-grid-left-btn:disabled {
  background-color: #fff;
  cursor: not-allowed;
}

.transcript-details-highlight-btn:hover {
  background-color: rgb(250, 250, 250);
  cursor: pointer;
}

.transcript-details-highlight-btn:disabled {
  background-color: #fff;
  cursor: not-allowed;
}

.transcript-details-action-delete-btn:hover {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.transcript-details-player-container1 {
  /* margin-left: 2px; */
  /* width: calc(100% - 460px); */
  height: 75px;
  background: white;
  display: flex;
  color: #77838f;
  padding: 0 8px;
  bottom: 0;
  left: 0px;
  right: 0;
  position: fixed;
  box-shadow: 3px -2px 3px rgba(0, 0, 0, 0.05);
  z-index: 1;
  align-items: center;
}
.playPauseButton {
  overflow: visible !important;
  box-shadow: none !important;
}
.shortcutIcon {
  position: absolute !important;
}
.shortcutIcon svg {
  width: 12px;
  margin-right: 5px;
}
/* Player Styling start */
.video-player-container {
  position: fixed;
  bottom: 75px;
  left: 7px;
}

.transcript-details-player {
  width: 100%;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

/* .transcript-details-player-container .transcript-details-player button {
  background: transparent;
  font-size: 2rem;
  border: none;
  width: 64px;
  color: #25da3c;
} */

.transcript-details-player-container .transcript-details-player .timer-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
}

.transcript-details-player-container
  .transcript-details-player
  .timer-background {
  width: 100%;
  height: 9px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.transcript-details-player-container .transcript-details-player .timer-current {
  background: #25da3c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #80d78c,
    #25da3c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #80d78c,
    #25da3c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 100%;
  transition: 1s ease;
}

.currentTime {
  display: grid;
  align-content: center;
  margin-right: 5px;
}

.duration {
  padding: 0 2px;
  display: grid;
  align-content: center;
  margin-left: -1px;
}

/* .transcript-details-player-container .transcript-details-player .volume {
  display: flex;
  align-items: center;
  padding: 0 8px;
} */

/* input[type="range"] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer; */
/* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
/* background: #25da3c;
  border-radius: 1.3px;
  border: 0.2px solid #77838f;
}

input[type="range"]::-webkit-slider-thumb { */
/* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
/* border: 1px solid #77838f;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #25da3c;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer; */
/* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
/* background: #25da3c;
  border-radius: 1.3px;
  border: 0.2px solid #77838f;
}

input[type="range"]::-moz-range-thumb { */
/* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
/* border: 1px solid #77838f;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type='range']::-ms-fill-lower {
  background: #25da3c;
  border: 0.2px solid #77838f;
  border-radius: 2.6px;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
/* }

input[type='range']::-ms-fill-upper {
  background: #25da3c;
  border: 0.2px solid #77838f; */
/* border-radius: 2.6px; */
/* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
/* }

input[type="range"]::-ms-thumb { */
/* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
/* border: 1px solid #77838f;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

input[type='range']:focus::-ms-fill-lower {
  background: #25da3c;
}

input[type='range']:focus::-ms-fill-upper {
  background: #25da3c;
} */

/* Player Styling end */

.transcript-details-player-right-panel {
  position: absolute;
  right: 15px;
  top: 16px;
  height: 30px;
  background-color: transparent;
  border: 1px solid rgb(119, 131, 143);
  border-radius: 4px;
  padding: 3px;
  font-weight: 400;
  outline: none;
}

.transcript-details-player-right-panel:hover {
  cursor: pointer;
  background-color: rgb(250, 250, 250);
}

/* Shortcut Dialog Styling */
.close-btn-shortcut-dialog {
  cursor: pointer;
  margin-left: auto;
  background-color: transparent;
  border: none;
  outline: none;
  z-index: 1;
}

.shortcut-dialog-title {
  margin-top: -35px;
}

.list-item-shortcut {
  font-weight: bold;
}

.transcript-details-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  margin-top: 50px;
  margin-left: 5%;
  padding: 0;
  list-style: none;
}

.timecode-text-edit-btn {
  display: flex;
  flex-direction: row;
  width: 0px;
}
.edit-btn {
  font-size: 12px !important;
  margin-left: -15px;
  position: relative;
  cursor: pointer;
}
.timecode-txt {
  font-size: 12px;
  margin-top: 2px;
}
.toggle-timecode {
  margin-left: 7px;
  margin-right: -35px;
}
.toogle-switches {
  width: 10px;
}
.duration-text-container {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}
.total-duration-text {
  font-size: 9px;
  margin-top: 8px;
}
.toggle-container {
  margin-top: -18px;
  position: relative;
  margin-left: 55px;
}
.timecode-toggle-edit-container {
  margin-top: 9px;
  margin-right: 21px;
}

.skeleton-editor {
  height: 50px !important;
}

.exportMenuSelector ul {
  min-width: auto !important;
}

.overflow-auto {
  overflow: visible;
}
