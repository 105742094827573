.dialog-title-bottom {
  color: rgba(0, 0, 0, 0.54);
}

.btn-group button {
  background-color: #fff;
  border: 1px solid rgb(240, 240, 240);
  border-radius: 5px;
  color: #000000;
  padding: 10px 15px;
  cursor: pointer;
  float: left;
  margin-bottom: 10px;
}

.dialog-content {
  display: flex;
  justify-content: space-between;
}

.dialog-content-subtitles {
  color: #fb2525;
}

.btn-group .no-btn-active {
  background-color: #fb2525;
  color: #fff;
}

.btn-group .no-btn-inactive {
  background-color: #fff;
  color: #000000;
}

.btn-group .yes-btn-inactive {
  background-color: #fff;
  color: #000000;
}
.btn-group .yes-btn-active {
  background-color: #04c61d;
  color: #fff;
}

.btn-group .initial-state-btn {
  background-color: #fff;
  color: black;
}
.dialog-content-top {
  margin-top: 10px;
  color: #000000;
}

.proceed-btn-container {
  width: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5px;
}

.dialog-checkbox-container {
  margin-left: 11px;
  margin-bottom: 13px;
}
