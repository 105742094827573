.toast-Container {
  padding: 10px;
}

.toast-title {
  font-weight: bold;
  font-size: 20px;
}

.Toastify__toast--success,
.Toastify__toast--error {
  border-radius: 8px !important;
}
.Toastify__close-button {
  font-weight: 400 !important;
}
.Toastify__close-button--success,
.Toastify__close-button--error {
  margin-top: 10px;
  margin-right: 10px;
}
