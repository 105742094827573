.projects-container {
  margin-top: 55px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 20px;
  width: 100%;
  max-width: 1020px;
}

.truncate-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
